import "core-js/modules/es.function.name.js";
var renderContent = function renderContent(value, row, index, key) {
  // let childrenVal;
  // if(index % 2 == 0){
  //   childrenVal = (<a href="javascript:;">111</a>);
  // }else {
  //   childrenVal = value;
  // }
  var obj = {
    children: value,
    attrs: {}
  };
  if (index % 2 == 1) {
    obj.attrs.colSpan = 0;
  }
  return obj;
};
var columns = [{
  title: '',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '11%',
  scopedSlots: {
    customRender: 'imgurl'
  },
  customRender: function customRender(value, row, index) {
    var obj = {
      children: h("a", [index]),
      attrs: {}
    };
    if (index % 2 == 0) {
      obj.attrs.rowSpan = 2;
    } else {
      obj.attrs.colSpan = 7;
      obj.children = row.name;
    }
    return obj;
  }
}, {
  title: '商品信息',
  dataIndex: 'info',
  key: 'info',
  width: '16%',
  scopedSlots: {
    customRender: 'info'
  },
  customRender: function customRender(value, row, index) {
    return renderContent(value, row, index, 'info');
  }
}, {
  title: 'Details',
  dataIndex: 'details',
  key: 'details',
  width: '20%',
  scopedSlots: {
    customRender: 'details'
  },
  customRender: renderContent
}, {
  title: '铸造时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '13%',
  scopedSlots: {
    customRender: 'createTime'
  },
  customRender: renderContent
}, {
  title: '当前价格',
  dataIndex: 'price',
  key: 'price',
  width: '9%',
  scopedSlots: {
    customRender: 'price'
  },
  customRender: renderContent
}, {
  title: '交易次数',
  dataIndex: 'times',
  key: 'times',
  width: '8%',
  scopedSlots: {
    customRender: 'times'
  },
  customRender: renderContent
}, {
  title: 'owner',
  dataIndex: 'owner',
  key: 'owner',
  width: '7%',
  scopedSlots: {
    customRender: 'owner'
  },
  customRender: renderContent
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '7%',
  scopedSlots: {
    customRender: 'status'
  },
  customRender: renderContent
}
//   {
//       title: '操作',
//       key: 'action',
//       dataIndex: 'action',
//     //   fixed: "right",
//       width: 200,
//       scopedSlots: {
//           customRender: 'action'
//       }
//   }
];

export { columns };