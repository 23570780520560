var searchKeys = [{
  key: "castingdate",
  label: "铸造时间",
  placeholder: ['开始时间', '截止时间'],
  required: false,
  rules: [],
  dates: true
}, {
  key: "itemname",
  label: "商品名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  // key: "price",
  label: "价格",
  placeholder: "请输入",
  required: false,
  rules: [],
  inputGroup: true,
  children: [{
    key: 'minprice',
    min: 0,
    placeholder: '请输入'
  }, {
    key: 'maxprice',
    min: 0,
    placeholder: '请输入'
  }]
}, {
  key: "owner",
  label: "Owner",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "collection",
  label: "Collection",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "creator",
  label: "Creator",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
},
// {
//   key: "blockChain",
//   label: "BlockChain",
//   placeholder: "请输入",
//   required: false,
//   rules: [],
//   input: true
// },
{
  key: "contractaddress",
  label: "Contract Address",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "state",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "mintstate",
  label: "铸造状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "type",
  label: "类型",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };